 <template>
  <v-container v-if="can_show">
    <v-row justify="center" class="">


        <!-- {{ array_to_print }} -->
        <!-- {{modelo_recibo}} -->
        <!-- {{dados_f_somas}} -->

        <!-- {{ items_alunos_imprimir }} -->
        <!-- {{ def }} -->
        <!-- {{emp}} -->
        <!-- {{dados_parcela[0]}} -->
        <!-- {{ dados_cadastro }} -->
        <!-- {{ dados_servico }} -->
        <!-- {{  dados_financeiro  }} -->
    



<v-dialog
    v-model="dialog_opcoes"
    max-width="699px"
    scrollable
>
  <v-card
    class="mx-auto"
  >
    <v-card-title class=" grey lighten-5">
      <h5>Definições</h5>

      <v-spacer></v-spacer>
      
      <v-icon
        color="red"
        @click="dialog_opcoes=!dialog_opcoes"
      >
        mdi-close
      </v-icon>

    </v-card-title>

    <v-card-text>
      <v-container class="mx-0 px-0 mt-2">
        <v-row>
          
          <v-col
            cols="12"
            md="10"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="def.motivo_processo"
              label="Motivo do Processo"
              hide-details
              type="text"
              @input="def.motivo_processo = def.motivo_processo.toUpperCase()"
            ></v-text-field>
          </v-col> 
          

          <v-col
            cols="12"
            md="1"
            class="ml-2"
          >      
          <v-btn color="primary"
          @click="dialog_opcoes=!dialog_opcoes"
          >
            ok
          </v-btn>
          </v-col> 
          

          
          <v-spacer></v-spacer>


        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>



        <!-- ATENDIMENTOS -->
        <v-card
            elevation="0"
            class=""
            width="100%"
        >
        <v-toolbar
            dark
            color="primary"
            elevation="0"
            class="no-print"
        >

            <v-spacer></v-spacer>

            <v-btn
                @click="imprimir()"
                color="primary text--white"
            >
            <v-icon color="white"> mdi-printer </v-icon>
            &nbsp imprimir
            </v-btn>

            <v-divider class="mx-2" vertical inset></v-divider>


            <v-btn
                color="primary"
                @click="close()"
            >
                
                <v-icon>mdi-close</v-icon>
                &nbsp 
                Fechar
                
            </v-btn>

        </v-toolbar>


        <!-- mostra conforme o modelo -->
        <v-container 
         fluid class="pa-2"
         v-if="(modelo_recibo=='carne')"
        >
        <v-row dense>

        <!-- COLUNA ESQUERDA -->
        <v-card
        class="no-print mt-1"
        width="5%"
        elevation="0"
        height="100"
        >
            <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                <v-row>
                    <v-col cols="3 offset-md-1">

                    </v-col>
                </v-row>
            </v-card>
        </v-card>   


        <!-- COLUNA CENTRAL -->
        <v-card  class="sem_borda">
            <v-card
              height="7.7cm"
              width="18.2cm"
              class="grey lighten-4 ml-1 mb-3 mb-10"
              v-for="(item, i ) in dados_parcela"
              :key="i"
            >

            <!-- {{i+1}} -->

            <v-row dense no-gutters>
              <v-card class="" width="4cm" height="7cm">
                <v-row no-gutters class="">
                    <v-card height="2cm" width="2.1cm" :class="[ noElevation+'  pt-1']">
                        <img :src="urlLogo" style="max-width: 2cm; height: 1cm"/>
                    </v-card>

                    <v-card width="1.8cm" :class="[noElevation + ' pl-2 pt-3']">
                        <div class="fs-8">
                            Parcela {{ item.PLANO }}
                        </div>
                        <div class="fs-8">
                            Vencimento
                        </div>
                        <div class="fs-9 font-weight-bold">
                           {{ getFormatDate(item.DATA_VENCIMENTO) }}
                        </div>
                    </v-card>
                </v-row>
                <v-divider/>

                <v-row no-gutters>
                    <v-card height="0.6cm" width="4cm" :class="[noElevation + ' px-1']">
                        <div class="fs-11 pt-1">
                            Documento Nº {{item.CODIGO}}
                        </div>
                    </v-card>
                </v-row>
                <v-divider/>

                <v-row no-gutters class="">
                    <v-card height="1.1cm" width="4cm" :class="[noElevation + ' px-1']">
                        <div class="fs-11 pt-1">
                            ( = ) Valor do Documento
                        </div>
                        <div class="fs-13 text-center font-weight-bold">
                            {{ item.x_double_TOTAL }}
                        </div>
                    </v-card>
                </v-row>
                <v-divider/>

                <v-row no-gutters class="">
                    <v-card height="1.2cm" width="4cm" :class="[noElevation + ' px-1']">
                        <div class="fs-11 py-1">
                            ( - ) Descontos
                        </div>
                        <div class="fs-11 ">
                            ( + ) Acréscimos
                        </div>
                    </v-card>
                </v-row>
                <v-divider/>

                <v-row no-gutters class="">
                    <v-card height="2.6cm" width="4cm" :class="[noElevation + ' px-1']">
                        <div class="fs-11 py-1">
                            ( = ) Total
                        </div>
                        <div class="fs-11 py-1 text-center font-weight-bold">
                            R$ _________________
                        </div>
                        <div class="fs-10 pb-2 ">
                            Data Pagamento
                        </div>
                        <div class="fs-11 text-center font-weight-bold">
                            ______/______/______
                        </div>
                    </v-card>
                </v-row>

              </v-card>


              <v-card :class="[noElevation + ' ' ]" width="14cm" height="6.1cm" tile>
                <v-row no-gutters class="">
                    <v-row no-gutters>
                    <v-card class="" width="11cm" height="6.1cm" tile>
                        <v-row no-gutters class="">

                            <v-card height="2cm" width="3.3cm" :class="[ noElevation+'  pt-2']">
                              <img :src="urlLogo" style="max-width: 3.2cm; max-height: 1.6cm"/>
                            </v-card>

                            <v-card height="2cm" width="7.5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-9 pt-1">
                                    Cedente
                                </div>
                                <div class="fs-10 bold">
                                    {{ emp.nome }}
                                </div>
                                <div class="fs-8">
                                   CNPJ: {{ emp.cnpj }}
                                </div>
                                <div class="fs-8">
                                   {{ emp.endereco }}
                                </div>
                                <div class="fs-8">
                                   {{ emp.fone1 }}
                                </div>
                            </v-card>
                        </v-row>


                        <v-row no-gutters class="blue">
                            <v-card height="0.6cm" width="3cm" :class="[noElevation + '  px-1']" tile outlined>
                                <div class="fs-8 py-1 text-center">
                                    Documento Nº {{item.CODIGO}}
                                </div>
                            </v-card>
                            <v-card height="0.6cm" width="3cm" :class="[noElevation + '  px-1']" tile outlined>
                                <div class="fs-8 py-1 text-center">
                                    Espécia R$
                                </div>
                            </v-card>
                            <v-card height="0.6cm" width="5cm" :class="[noElevation + '  px-1']" tile outlined>
                                <div class="fs-8 py-1 text-center">
                                    Processamento {{getFormatDate(today)}}
                                </div>
                            </v-card>
                        </v-row>
                        <v-divider/>


                        <v-row no-gutters class="">
                            <v-card height="3.4cm" width="11cm" :class="[noElevation + '  px-1']" tile outlined >
                                <div class="fs-8 bold py-2">
                                    {{item.SERVICO_REFERENTE}}
                                </div>

                                <v-row no-gutters>
                                    <div class="fs-8  py-1">
                                        IDE: {{dados_cadastro.CODIGO_ALUNO}}
                                    </div>
                                    <div class="fs-8  py-1 pl-2">
                                        Processo: {{dados_servico.PROCESSO}}
                                    </div>
                                    <div class="fs-8  py-1 pl-2">
                                        Cat: {{dados_servico.CATEGORIA}}
                                    </div>
                                </v-row>

                                <div class="fs-9 bold py-5">
                                    ** Atraso acarretará Juros **
                                </div>

                            </v-card>
                        </v-row>
                        <v-divider/>

                    </v-card>

                    <v-card class="" width="3cm" height="6cm" tile >
                        <v-row no-gutters>
                            <v-card height="0.6cm" width="5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-9 py-1">
                                    Parcela Nº {{item.PLANO}}
                                </div>
                            </v-card>
                        </v-row>
                        <v-divider/>
                        
                        <v-row no-gutters>
                            <v-card height="0.9cm" width="5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-10">
                                    Vencimento
                                </div>
                                <div class="fs-11 bold pl-4">
                                {{ getFormatDate(item.DATA_VENCIMENTO) }}
                                </div>
                            </v-card>
                        </v-row>
                        <v-divider/>
                        
                        <v-row no-gutters>
                            <v-card height="1cm" width="5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-9 pt-1">
                                    ( = ) Valor do Documento
                                </div>
                                <div class="fs-11 bold pr-2 text-right">
                                {{ item.x_double_TOTAL }}
                                </div>
                            </v-card>
                        </v-row>
                        <v-divider/>

                        <v-row no-gutters class="">
                            <v-card height="1cm" width="5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-9 py-1">
                                    ( - ) Descontos
                                </div>
                                <div class="fs-9 ">
                                    ( + ) Acréscimos
                                </div>
                            </v-card>
                        </v-row>
                        <v-divider/>


                        <v-row no-gutters class="">
                            <v-card height="2.4cm" width="5cm" :class="[noElevation + ' px-1']">
                                <div class="fs-9 py-1">
                                    ( = ) Total Cobrado
                                </div>
                                <div class="fs-9 py-2 text-center font-weight-bold">
                                    R$ _____________________
                                </div>
                                <div class="fs-9 pb-2 ">
                                    Data Pagamento
                                </div>
                                <div class="fs-9 text-center font-weight-bold">
                                    ______/______/______
                                </div>
                            </v-card>
                        </v-row>

                    </v-card>
                    </v-row>

                </v-row>                        

                <v-row no-gutters class="">
                    <v-card height="1.6cm" width="14cm" :class="['  px-2']" tile >
                        <div class="fs-8  pt-1">
                            Sacado
                        </div>

                        <v-row no-gutters>
                          <div class="fs-8 bold py-0">
                            {{dados_cadastro.NOME}}
                          </div>
                          <div class="fs-8  py-0 ml-2">
                            CPF:  {{dados_cadastro.CPF}}
                          </div>
                        </v-row>

                        <div class="fs-8  pt-1">
                            {{dados_cadastro.ENDERECO}} {{space}} {{dados_cadastro.BAIRRO}} {{space}} CEP: {{dados_cadastro.CEP}} {{space}} {{dados_cadastro.CIDADE}} - {{dados_cadastro.UF}}
                        </div>

                   </v-card>
                </v-row>
              </v-card>
            </v-row>

            <div :class="quebra_pagina(i+1, 3)"> </div>
            </v-card>
        </v-card>   

        <v-spacer></v-spacer>
        
        <!-- COLUNA DIREITA -->
        <v-card
        class="no-print mt-1"
        width="300"
        elevation="0"
        height="100"
        v-if="0>1"
        >

            <v-card class="mt-3 ml-2 grey lighten-4">
                <v-row>
                    <v-col cols="4 offset-md-1">
                        <!-- <v-card> -->
                            <!-- <v-text-field
                            v-model="nVias"
                            label="Nº de Vias"
                            type="number"
                            @input="mudaVias(nVias)"
                            ></v-text-field> -->

                            <v-select
                            v-model="nVias"
                            :items="['1','2']"
                            label="Nº de Vias"
                            @input="mudaVias(nVias)"
                            ></v-select>
                        <!-- </v-card> -->
                    </v-col>
                </v-row>
            </v-card>
        </v-card> 

        </v-row>
        </v-container>

        <!-- O PULO DO GATO HAHAHA -->
        <v-container 
        fluid class="pa-1"
        v-else
        >
        <!-- v-if="(modelo_recibo=='ficha')||(modelo_recibo=='pre-solicitacao')" -->
            <v-row dense>


                <!-- COLUNA ESQUERDA -->
                <v-card
                class="no-print mt-1"
                width="5%"
                elevation="0"
                height="100"
                >
                    <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                        <v-row>
                            <v-col cols="3 offset-md-1">

                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>  

            <!-- COLUNA PRINCIPAL -->
            <v-card
                class="sem_borda grey lighten-5 full"
                min-width="40%"
                width="19cm"
                height="26.5cm"
            >
                <v-row 
                v-for="(item, i) in array_to_print"
                :key="i"
                dense
                no-gutters
                >


                
                
                <!-- QUANDO TEM MAIS DE UMA COLUNA NA MESMA LINHA -->
                <v-col 
                    v-if="(item.colunas)"
                    v-for="(sub_item, sub_i) in item.colunas"
                    :key="sub_i"
                    :class="[sub_item.col_class  + class_default]"
                    :cols="sub_item.col_cols"
                >



                   <div  
                     :style= "[{'height':sub_item.card_h, 'width': sub_item.card_w}]"
                     :class="[sub_item.card_class, '']"
                    >
                    
                    


                    <v-card v-if="sub_item.tag=='bloco'" class="ml-1"   max-height="0.6cm" tile elevation="0"
                     v-for="(F, Findex) in 30"
                    >

                        
                        <v-row dense v-if="(sub_item['B'+F])">
                            <v-divider vertical class="black mx-0"></v-divider>

                            <v-card  tile elevation="1" width="9cm" height="0.5cm" class="d-flex align-center pl-1 colunas_bottom" >
                                <span :class="(sub_item['boldB'+F]=='S') ? [sub_item.class, 'bold']: sub_item.class">
                                {{sub_item['B'+F]}} 
                                </span>
                            </v-card>

                            <v-card elevation="1" class=" d-flex align-center justify-center colunas_bottom" tile width="0.5cm" height="0.5cm" 
                              v-for="(n, index) in 20"
                              :key="index"
                            >
                            
                            <!-- style="border-bottom: 1px  solid black;" -->
                                <span class="fs-7" v-if="(sub_item['show_number'+F]=='S')">
                                    {{ n }}º
                                </span>
                                
                            </v-card>
                        </v-row> 
                            
                    </v-card> 
                      


                    <img v-if="sub_item.tag=='img'" :src="urlLogo" :style= "[{'height': sub_item.h, 'max-width': sub_item.w}]"/>

            
        

                    <div v-if="sub_item.tag=='div'" :class="[sub_item.class, '']">  


                        <div
                          v-for="(F, Findex) in fields_coluna"
                          :key="Findex"
                          class="inline"
                        >

                            <span 
                             :class="(sub_item['boldB'+F]=='S') ? 'bold': sub_item.class"
                             :style="[(sub_item['B'+F]) &&(sub_item.style) ? sub_item.style : '']"
                            > {{sub_item['B'+F]}}  </span>

                            <span
                              :class="(sub_item['boldL'+F]=='S') ? 'bold': sub_item.class"
                              :style="[(sub_item['LABEL'+F]) &&(sub_item.style) ? sub_item.style : '']"
                            > {{sub_item['LABEL'+F]}}   <br v-if="sub_item['br'+F]"/> </span>

                            <div v-if="(sub_item.table)" class="inline">

                                <span :class="(sub_item['boldF'+F]=='S') ? 'bold': sub_item.class">   
                                    <span v-if="sub_item['F'+F+'_DATE']=='S'">
                                        {{ getFormatDate(sub_item.table[sub_item['F'+F]]) }}
                                    </span>
                                    <span v-else>
                                        {{ sub_item.table[sub_item['F'+F]] }}
                                    </span>
                                        
                                </span>                                
                            </div>

                        </div>
                    </div>
                </div>


                <v-divider v-if="sub_item.tag=='divider'" :class="sub_item.class"></v-divider>

                <div :class="[sub_item.class]" v-if="sub_item.tag=='corta_linha'" >
                   <center>
                    <v-divider  class="grey mx-15"></v-divider>
                    <v-icon x-small style="margin-top:-30px">
                        mdi-scissors-cutting 
                    </v-icon>
                   </center>
                </div>
                
                </v-col>



                <!-- PERCORRER UMA SUB TABELA = TABELA2 -->
                <v-col v-if="item.sub_tabela" cols="12" :class="class_default">
                    <v-row dense no-gutters
                        v-for="(sub_item, sub_i) in item.sub_tabela[0].table.length"
                        :key="sub_i"
                        :class="[sub_item.col_class]"
                        :cols="sub_item.col_cols"
                    >
                
                    <!-- :style= "[isMobile ? {'height':'55px', 'margin-top': '0px'} : {'height':'40px'}]" -->
                    
                    <!-- {{ sub_i }} -->
                        
                            <v-col
                                v-for="(sub_item2, sub_i2) in item.sub_tabela"
                                :key="sub_i2"
                                class=""
                                :cols="sub_item2.col_cols"
                                :class="[sub_item2.col_class]"
                            >

                                <div v-if="sub_item2.tag=='div'" :class="sub_item2.class">  

                                    <div
                                     v-for="(F, Findex) in fields_coluna"
                                     :key="Findex"
                                     class="inline"
                                    >
                                    <!-- {{  F  }} -->

                                        <span :class="[(sub_item2['boldB'+F]=='S') ? 'font-weight-bold':'', sub_item2.class]"> 
                                            <!-- {{sub_item2['B'+F]}} -->
                                            {{  (sub_item2['B'+F] == 'INDEX')? sub_i+1 : sub_item2['B'+F] }}
                                        </span>

                                        <span :class="[(sub_item2['boldF'+F]=='S') ? 'font-weight-bold':'', sub_item2.class]">   

                                            <span v-if="(sub_item2.vELSE) && (sub_item2.table[sub_i][sub_item2.vIFcampo] != sub_item2.vIFvalor) && ( sub_item2.table[sub_i][sub_item2['F'+F]])">
                                                {{  sub_item2.vELSE}}
                                            </span>

                                            <span v-else-if="(sub_item2.vIFcampo) && (sub_item2.table[sub_i][sub_item2.vIFcampo] == sub_item2.vIFvalor) && ( sub_item2.table[sub_i][sub_item2['F'+F]])">
                                                <span> {{ sub_item2['Bif'+F] }}</span>
                                                {{ sub_item2.table[sub_i][sub_item2['F'+F]] }}
                                                <span> {{ sub_item2['Aif'+F] }}</span>
                                            </span>
                                            

                                            <span v-else>
                                                <span v-if="sub_item2['F'+F+'_DATE']=='S'">
                                                    {{ getFormatDate(sub_item2.table[sub_i][sub_item2['F'+F]]) }}
                                                </span>
                                                <span v-else>
                                                    {{ sub_item2.table[sub_i][sub_item2['F'+F]] }}
                                                </span>
                                            </span>

                                        </span>
                                    </div>

                                    
                                </div>

                                <v-divider v-if="sub_item2.tag=='divider'" :class="sub_item2.class"></v-divider>

                            </v-col>

                    </v-row>
                </v-col>



                <!-- V-ELSE   DEIXAR ESSE DAQUI SEMPRE NO FINAL -->
                <!-- SOMENTE 1 COLUNA NA LINHA -->
                <v-col 
                    v-else 
                    :class="[item.col_class + class_default]"
                    :cols="item.col_cols"
                >
                    <img v-if="item.tag=='img'" :src="urlLogo" :style= "[{'height': item.h, 'max-width': item.w}]"/>

                    <div v-if="item.tag=='div'" :class="[item.class]">  
                        <div :class="(item.boldB1=='S') ? 'font-weight-bold':''">   {{item.B1}}  </div>
                        <div :class="(item.boldL1=='S') ? 'font-weight-bold':''">   {{item.LABEL1 }} </div>
                    </div>
                    
                    <v-divider v-if="item.tag=='divider'" :class="item.class"></v-divider>
                </v-col>



                </v-row>


                <!-- FOOTER -->
                <v-row 
                v-for="(item, index) in array_to_print"
                :key="item.CODIGO"
                dense
                no-gutters
                class="footer_class"
            >
                    <v-col 
                        v-if="item.footer"
                        v-for="(sub_item, sub_i) in item.footer"
                        :key="sub_i"
                        :class="[sub_item.col_class]"
                        :cols="sub_item.col_cols"
                    >
                        <div v-if="sub_item.tag=='div'" :class="sub_item.class">  
                            <div
                              v-for="(F, Findex) in fields_coluna"
                              :key="Findex"
                              class=""
                             >
                             <!-- class="inline" -->
                                <span :class="[(sub_item['boldB'+F]=='S') ? 'font-weight-bold':'' , sub_item.class]">   {{sub_item['B'+F]}}  </span>
                                <span :class="[(sub_item['boldL'+F]=='S') ? 'font-weight-bold':'' , sub_item.class]">   {{sub_item['LABEL'+F] }} </span>
                            </div>
                        </div>
                    </v-col>
                </v-row>

            </v-card>

            </v-row>
        </v-container>



        </v-card>

        
    </v-row>
  </v-container>
 </template>
  
  <script>
  
  // import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
  import { logado } from '../assets/js/DSi_rotinas'
  
  export default {
  
  
      data: () => ({
  

          can_show: false,

          isMobile: false,

          emp:{},

          def:{motivo_processo:''},
          dialog_opcoes: false,

          nVias: '2',
          objVias:[],
          urlLogo:'',

          class_default:' rounded ',

          fields_coluna: 10,

          space : '⠀',

          noElevation :' elevation-0 ',
          comBordas :' elevation-2  ',

          array_to_print:[],
            
          items_alunos_imprimir:[],
          dados_parcela:[],
          dados_cadastro:[],
          dados_servico:[],
          dados_financeiro:[],
          dados_f_somas:[],
          dados_titulo1 :'',
          dados_titulo2 :'',
  
          items_retorno: [],
  
          cor_teste:'red',
  
          recebe_obj:{},
  
          modelo_recibo: '',
  
          array_A:[],
          array_B:[],
          array_C:[],
  
          soma:0,
  
  
          hoje :'',
          hoje_week:'',
          hora :'',
          data_hora :'',
          today :'',
  
  
      }),
  
      created(){
        this.onCreated()
      },
  
    methods:{
  
        async onCreated(){

          if (await logado(this)){
          
            await this.showDrawer(false)
            this.carrega_dados()
            this.imprimir_modelo()
          }
        },

        async showDrawer(value){
          this.$emit('executar', value)//mostrar ou nao paineis
          this.can_show = true
          this.isMobile = window.innerWidth < 600
        },
        
    quebra_pagina(count, mult){
        if (count%mult == 0){
            return 'pagebreak'
        }
        else {
            return ''
        }
        
    },
    
    desenha(nome, sub_items){
        let obj = {}//clear       
        this.$set(obj, nome, sub_items );
        this.array_to_print.push(obj)
    },
  

    monta_impressao(modelo){

        // let space = '⠀⠀⠀⠀⠀⠀⠀⠀⠀'
        let space = '⠀'
        // let space = this.espace

        if (modelo=='avaliacao_20'){

            this.desenha('colunas',
            [ 
            //   { tag:'img', col_class:'text-center pt-1 pl-1',col_cols:'1', w:'auto', h:'1.5cm' },
              { tag:'div', col_class:'text-left pt-0',col_cols:'6', class:' fs-13 ',  LABEL1: this.emp.nome , boldL1:'S' },
              { tag:'div', col_class:'text-right pt-0',col_cols:'6', class:' fs-13 ',  LABEL1: 'AVALIAÇÃO PRÁTICA' , boldL1:'S' },

              { tag:'div', col_class:'my-n1 text-left pt-0',col_cols:'6', class:' fs-10 ',  LABEL1: this.emp.endereco , boldL1:'N' },
              { tag:'div', col_class:'text-right pt-0',col_cols:'6', class:' fs-13 ',  LABEL1: this.dados_servico.PROCESSO , boldL1:'S' },

              { tag:'div', col_class:'my-n2 text-left pt-0',col_cols:'8', class:'fs-13 ',  
                 B1: `⠀`+ this.dados_cadastro.NOME + ':', boldB1:'S', LABEL1: this.dados_cadastro.CODIGO_ALUNO  , boldL1:'S',   
              },
              
              { tag:'div', col_class:'my-n1 text-right pt-0',col_cols:'4', class:'fs-13 ',  
                 B1: this.dados_servico.DESCRICAO + '-', boldB1:'N', LABEL1: this.dados_servico.CATEGORIA , boldL1:'N',   
              },

              
             { tag:'div', col_class:'',col_cols:'9', class:'fs-11', table: this.dados_cadastro,
               B1:`⠀`,           boldB1:'N', 
               F1:'x_IDADE',      boldF1:'',

               B2:`⠀⠀RG:⠀`,     boldB2:'S', 
               F2:'RG',          boldF2:'n',

               B3:`⠀CPF:⠀`,      boldB3:'S', 
               F3:'CPF',         boldF3:'',

               B4:`⠀|⠀`,         boldB4:'S', 
               F4:'CELULAR',     bold4:'',

            //    B5:`⠀`,            boldB5:'S', 
            //    F5:'CELULAR_2',    bold5:'',

               B6:`⠀|`,            boldB6:'S', 
               F6:'CIDADE',       bold6:'',
             },    
             

             { tag:'div', col_class:'text-right pt-0',col_cols:'3', class:'fs-10 ',  
                 B1: 'LADV:_______________', boldB1:'N', LABEL1: `V:______________` , boldL1:'N',   
             },

             { tag:'div', col_class:'text-left pt-0 mb-1',col_cols:'6', class:'fs-12 ',  
                 B1: 'Veículo:___________________________________________', boldB1:'S', LABEL1: `` , boldL1:'N',   
             },

             { tag:'div', col_class:'text-right pt-0',col_cols:'6', class:'fs-12 ',  
                 B1: 'Obs:___________________________________________', boldB1:'S', LABEL1: `` , boldL1:'N',   
             },

            ])

            // this.array_to_print.push({ tag:'divider',  class:'black ma-1'})


            this.desenha('colunas',
            [                          

            //   { tag:'divider', col_cols:'12', class:'black my-1 mx-0'},

              { tag:'bloco', col_class:'text-left', col_cols:'12', class:'fs-9',
                 B1:  `MANOBRAS`,                     boldB1:'S', show_number1:'S',
                 B2:  `1 - Posição do Aluno`,         
                 B3:  `2 - Instrumento do Painel`, 
                 B4:  `3 - Utilização dos Pedais`,  
                 B5:  `4 - Partida`,  
                 B6:  `5 - Arranque`,  
                 B7:  `6 - Paradas`,
                 B8:  `7 - Domínio da Embreagem`,
                 B9:  `8 - Troca de Marchas`,
                 B10: `9 - Controle de Direção`,
                 B11: `10 - Observação da Sinalização`,
                 B12: `11 - Uso de Sinais`,
                 B13: `12 - Redução de Marchas`,
                 B14: `13 - Conversões`,
                 B15: `14 - Rampa`,
                 B16: `15 - Slalom`,
                 B17: `16 - Marcha à Ré`,
                 B18: `17 - Baliza`,
                 B19: `18 - Comportamento no Trânsito`,
                 B20: `19 - Controle de Velocidade`,
                 B21: `20 - Direção Defensiva`,
              }, 
            ])

            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-left pt-1',col_cols:'12', class:'fs-10 ',  
                  B1: 'Avaliação Final:', boldB1:'S', LABEL1: `⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀` , boldL1:'N',   
                  B2: 'Conceito:⠀⠀', boldB2:'N', LABEL2: `` , boldL2:'N',   
                  B3: '⠀⠀⠀N', boldB3:'S', LABEL3: ` = Não` , boldL3:'N',   
                  B4: '⠀⠀⠀S', boldB4:'S', LABEL4: ` = Sim` , boldL4:'N',   
                  B5: '⠀⠀⠀R', boldB5:'S', LABEL5: ` = Regular` , boldL5:'N',   
                  B6: '⠀⠀⠀B', boldB6:'S', LABEL6: ` = Bom` , boldL6:'N',   
                  B7: '⠀⠀⠀O', boldB7:'S', LABEL7: ` = Ótimo` , boldL7:'N',   
              },
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1 mt-4'})
            this.array_to_print.push({ tag:'divider',  class:'black ma-1 mt-4'})


            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: '___________________________________________', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: '___________________________________________', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: '___________________________________________', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },
            ])

            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: 'Instrutor', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: 'Aluno', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'4', class:'fs-10 ',  
                  B1: 'Diretor(a) de Ensino', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },
            ])
            

        }

        // else if (modelo=='Processos para Apropriação'){
        else if (['Processos para Apropriação','Malote'].includes(modelo)){


            
   
            // this.array_to_print.push({ tag:'img', col_class:'text-center', w:'auto', h:'2.5cm'  })

            // this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
                // { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},

                // { tag:'img', col_class:'',col_cols:'4', w:'3cm', h:'auto' },
                { tag:'div', col_class:'text-left pt-0',col_cols:'6', class:' fs-13 ',  LABEL1: this.emp.nome , boldL1:'S' },
                { tag:'div', col_class:'text-right pt-0',col_cols:'6', class:' fs-13 ',  LABEL1: this.hoje , boldL1:'S' },
                { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-16',  LABEL1: this.modelo_recibo, boldL1:'S' },
                { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},
            ])


            this.desenha('colunas',
            [
                { tag:'div', col_class:'text-start  ',col_cols:'2', class:' fs-11',  LABEL1: `⠀⠀`+'PROCESSO', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-start  ',col_cols:'7', class:' fs-11',  LABEL1: 'NOME', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-start  ',col_cols:'3', class:' fs-11',  LABEL1: 'SITUAÇÃO', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},

            ])

            this.desenha('sub_tabela',
            [
                { tag:'div', col_class:'pl-1',col_cols:'2', class:' fs-11', table: this.items_alunos_imprimir, F1: '', F2: 'PROCESSO', boldF1:'S', B2: `⠀`, B1:'INDEX', boldB1:'S', },
                { tag:'div', col_class:'',col_cols:'7', class:' fs-11', table: this.items_alunos_imprimir, F1: 'NOME', boldF1:'N'  },
                { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},
            
            ])



            this.desenha('colunas',
            [ 

              { tag:'divider', col_cols:'12', class:'white my-5 mx-1'},//espaço linha em branco


              { tag:'div', col_class:'text-center pt-1',col_cols:'6', class:'fs-10 ',  
                  B1: '_________________________________________________', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'6', class:'fs-10 ',  
                  B1: '_________________________________________________', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

            ])

            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-center pt-1',col_cols:'6', class:'fs-10 ',  
                  B1: 'Assinatura Responsável do CFC', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

              { tag:'div', col_class:'text-center pt-1',col_cols:'6', class:'fs-10 ',  
                  B1: 'Assinatura Responsável do Detran', boldB1:'N', LABEL1: `` , boldL1:'N',   
              },

            ])

            


        }

        else if (modelo=='ficha'){
   
            this.array_to_print.push({ tag:'img', col_class:'text-center', w:'auto', h:'2.5cm'  })

            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
            { tag:'div', col_class:'text-center',col_cols:'12', class:'', table: this.dados_cadastro, boldB1:'S', B1:'', boldF1:'S', F1: 'NOME', B2:`${space}`, F2:'CODIGO_ALUNO', boldF2:'S'},
            { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-12', table: this.dados_servico, boldB1:'S', B1:'', boldF1:'S', F1: 'DESCRICAO', B2:`${space} | ${space}PROCESSO:`, F2:'PROCESSO', boldF2:'S'},
            ])
            
            
            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            
            this.desenha('colunas',
            [
            { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ',table: this.dados_cadastro, boldB1:'S', B1:'CONTATO:⠀', boldF1:'', F1: 'CELULAR', B2:'⠀', F2:'CELULAR_2', B3:'⠀', F3:'FONE', B4:'', F4:'' },
            { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', table: this.dados_cadastro, boldB1:'S', B1:'ENDEREÇO:⠀', boldF1:'', F1: 'ENDERECO', B2:'⠀CEP:', F2:'CEP', B3:'⠀', F3:'CIDADE', B4:' - ', F4:'UF' },   
            { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'S', B1:`NASCIMENTO:${space}`, boldF1:'', F1: 'DATA_NASCIMENTO', F1_DATE:'S', B2:`${space}${space}NATURALIDADE:${space}`,boldB2:'S', F2:'NATURALIDADE', boldF2:''},
            { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'S', B1:`CPF:${space}`, boldF1:'', F1: 'CPF', B2:`${space}${space}RG:${space}`,boldB2:'S', F2:'RG', boldF2:''},
            { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'S', B1:`PAI:${space}`, boldF1:'', F1: 'NOME_PAI', B2:`${space}${space}MÃE:${space}`,boldB2:'S', F2:'NOME_MAE', boldF2:''},
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
            { tag:'div', col_class:'text-center text-decoration-underline mb-2',col_cols:'12', class:' fs-11',  LABEL1: 'CONDIÇÕES DE PAGAMENTO', boldL1:'S', B1: ``, boldB1:'S',  }
            ])

            this.desenha('sub_tabela',
            [
                { tag:'div', col_class:'',col_cols:'1', class:' fs-11', table: this.dados_parcela, F1: 'PLANO', boldF1:'S', B1: ''  },
                { tag:'div', col_class:'',col_cols:'2', class:' fs-11', table: this.dados_parcela, F1: 'x_double_TOTAL', boldF1:'S', B1: `VALOR: `, boldB1:'S'  },
                { tag:'div', col_class:'',col_cols:'2', class:' fs-11', table: this.dados_parcela, F1_DATE:'S', F1: 'DATA_VENCIMENTO', boldF1:'S', B1: `VENC.: `, boldB1:'S',  },

                { tag:'div', col_class:'',col_cols:'2', class:' fs-11', table: this.dados_parcela, F1_DATE:'S', F1: 'DATA_EFETUADO', boldF1:'S', B1: `RECEB.: `, boldB1:'S', F2:'', B2:``,
                    vIFcampo:'PAGO', vIFvalor: 'S', vELSE:`____/____/____ `        
                },
                
                { tag:'div', col_class:'',col_cols:'5', class:' fs-11', table: this.dados_parcela, F1: 'FORMA_PAGAMENTO', boldF1:'S', B1: ``, boldB1:'S', F2:'', B2:``,
                    vIFcampo:'PAGO',vIFvalor: 'S',  vELSE:`_________________________________________________________`        
                },
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
            { tag:'div', col_class:'text-center text-decoration-underline mb-2',col_cols:'12', class:' fs-11',  LABEL1: 'OUTROS PAGAMENTOS', boldL1:'S', B1: ``, boldB1:'S',  }
            ])

            this.desenha('colunas',
            [
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-11',  LABEL1: `R$ ___________________ ${space} ____/____/____  ______________________________`, boldL1:'S', B1: ``, boldB1:'S',  },
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
            { tag:'div', col_class:'text-center text-decoration-underline mb-2',col_cols:'12', class:' fs-11',  LABEL1: 'EXAMES DO PROCESSO', boldL1:'S', B1: ``, boldB1:'S',  }
            ])

            this.desenha('colunas',
            [
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-10',  LABEL1: `Ex.Ap.Física ${space}  ______/______   __________________ ${space} (${space}${space}) Apto ${space}${space} (${space}${space}) Inapto`, boldL1:'S', B1: ``, boldB1:'',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-10',  LABEL1: `Ex.Teórico  ${space}______/______   __________________ ${space} (${space}${space}) Apto ${space}${space} (${space}${space}) Inapto`, boldL1:'S', B1: `${space}`, boldB1:'',  },

                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-10',  LABEL1: `Aval.Psico   ${space}${space} ______/______   __________________ ${space} (${space}${space}) Apto ${space}${space} (${space}${space}) Inapto`, boldL1:'S', B1: ``, boldB1:'',  },
                { tag:'div', col_class:'mb-2',col_cols:'6', class:' fs-10',  LABEL1: `Ex.Prático   ${space} ______/______   __________________ ${space} (${space}${space}) Apto ${space}${space} (${space}${space}) Inapto`, boldL1:'S', B1: `${space}`, boldB1:'',  },
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1'})

            this.desenha('colunas',
            [
            { tag:'div', col_class:'text-center text-decoration-underline mb-2',col_cols:'12', class:' fs-11',  LABEL1: 'ANOTAÇÕES', boldL1:'S', B1: ``, boldB1:'S',  }
            ])

            this.array_to_print.push({ tag:'divider',  class:'black ma-1 mt-6'})
            this.array_to_print.push({ tag:'divider',  class:'black ma-1 mt-6'})
            this.array_to_print.push({ tag:'divider',  class:'black ma-1 mt-6'})

            this.desenha('footer',
            [
                { tag:'div', col_class:'text-center mb-5 ',col_cols:'12', class:' fs-11 ',  LABEL1: 'Declaração: __________________________________________________________________________________________________________________________', boldL1:'S', B1: ``, boldB1:'S',  },

                { tag:'div', col_class:'text-center', col_cols:'4', class:'fs-11 ',  LABEL1: '________________________________________', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-center ',col_cols:'4', class:'fs-11 ',  LABEL1: '________________________________________', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-center ',col_cols:'4', class:'fs-11 ',  LABEL1: '________________________________________', boldL1:'S', B1: ``, boldB1:'S',  },

                { tag:'div', col_class:'text-center ',col_cols:'4', class:'fs-11 ',  LABEL1: 'ALUNO', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-center ',col_cols:'4', class:'fs-11 ',  LABEL1: 'INSTRUTOR', boldL1:'S', B1: ``, boldB1:'S',  },
                { tag:'div', col_class:'text-center ',col_cols:'4', class:'fs-11 ',  LABEL1: 'DIRETOR', boldL1:'S', B1: ``, boldB1:'S',  },           
            ])



        }

        else if (modelo=='pre-solicitacao'){

            // abre janela de opções.. motivo do processo
            this.dialog_opcoes = true

             // MODELO CARD
            //  {card_class:'orange elevation-8', card_h:'1cm', card_w:'5cm' },

            this.desenha('colunas',
            [ 

              { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},

              { tag:'img', col_class:'',col_cols:'4', w:'3cm', h:'auto' },
              { tag:'div', col_class:'text-center pt-3',col_cols:'4', class:' fs-14',  LABEL1: 'Ficha de Pré-solicitação', boldL1:'S' },
              { tag:'div', col_class:'text-center elevation-2 pt-15 mt-0',col_cols:'4', class:'fs-11 ',  LABEL1: `___________________________________`, boldL1:'N', LABEL2: `Assinatura do Diretor Geral`, boldL2:'', br1:'S',},

              { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},

              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', LABEL1: 'Motivo do Processo:', boldL1:'N', table: this.def, F1:'motivo_processo', boldF1:'S' },
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', table: this.dados_servico, boldB1:'N', B1:'Prontuário / Processo:⠀', boldF1:'S', F1: 'PROCESSO',  B2:'⠀ Categoria Pretendida:⠀',boldF2:'S', F2:'CATEGORIA', }, 
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', table: this.dados_cadastro, boldB1:'N', B1:'Candidato / Condutor:⠀', boldF1:'S', F1: 'NOME',B2:'⠀CNH:⠀',boldF2:'S', F2:'CNH',  }, 
              
              { tag:'divider', col_cols:'12', class:'black my-1 mx-5'},
              
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', table: this.dados_cadastro, boldB1:'N', B1:'Contato:⠀', boldF1:'S', F1: 'CELULAR', B2:'⠀',boldF2:'S', F2:'CELULAR_2', boldF3:'S', F3:'FONE',B3:'⠀'  }, 
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ', table: this.dados_cadastro, boldB1:'N', B1:'Endereço:⠀', boldF1:'S', F1: 'ENDERECO', B2:'⠀CEP:', F2:'CEP', B3:'⠀', F3:'CIDADE', B4:' - ', F4:'UF' },   
      
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'N', B1:`Nascimento:${space}`, boldF1:'S', F1: 'DATA_NASCIMENTO', F1_DATE:'S', B2:`${space}${space}NATURALIDADE:${space}`,boldB2:'N', F2:'NATURALIDADE', boldF2:'S'},
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'N', B1:`CPF:${space}`, boldF1:'S', F1: 'CPF', B2:`${space}${space}RG:${space}`,boldB2:'N', F2:'RG', boldF2:'S', B3:`${space}${space}EMAIL:`,boldB3:'N', F3:'EMAIL', boldF3:'S'},
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11', table: this.dados_cadastro, boldB1:'N', B1:`Pai:${space}`, boldF1:'S', F1: 'NOME_PAI', B2:`${space}${space}Mãe:${space}`,boldB2:'N', F2:'NOME_MAE', boldF2:'S'},

              { tag:'divider',col_cols:'12',  class:'black my-1 mx-5'},

              { tag:'div', col_class:'',col_cols:'2', class:'fs-11 ',  LABEL1:`(${space})`, boldL1:'S', LABEL2:`${space} Solicito EAR`, boldL2:'N', },
              { tag:'div', col_class:'',col_cols:'2', class:'fs-11 ',  LABEL1:`(${space})`, boldL1:'S', LABEL2:`${space} Não Solicito EAR`, boldL2:'N', },
              { tag:'div', col_class:'',col_cols:'12', class:'fs-11 ',  LABEL1:`(${space})`, boldL1:'S',  boldL2:'N', 
                LABEL2:`${space} Solicito que a CNH seja entregue no Endereço do CFC: ${space}`+ this.emp.endereco, 
              },

              { tag:'divider',col_cols:'12',  class:'black my-7 mx-5'},
              { tag:'divider',col_cols:'12',  class:'black my-3 mx-5'},

              { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-12 mb-7',  LABEL1:`Declaro como verdadeiras as informações acima prestadas e responsabilizo-me civil e criminalmente pelas mesmas.`, boldL1:'S', LABEL2:``, boldL2:'', },

              { tag:'div', col_class:'',col_cols:'6', class:'fs-11 ',  LABEL1: this.emp.cidade + ' - '+ this.hoje_week, boldL1:'N', LABEL2:``, boldL2:'', },
              { tag:'divider',col_cols:'6',  class:'black my-3 mx-5'},

              { tag:'divider',col_cols:'6',  class:'white'},//espaço em branco..somente para dar espaço entre colunas
              { tag:'div', col_class:'text-center',col_cols:'6', class:'fs-11 ',  LABEL1: `Assinatura do Aluno`, boldL1:'N', LABEL2:``, boldL2:'', },

              { tag:'divider', col_cols:'12', class:'black my-1 mx-1'},

            //   { tag:'corta_linha', col_cols:'12', class:'my-5'},


            ])

            

        }

        else if (modelo=='financeiro'){
            this.desenha('colunas',
            [ 
            //   { tag:'img', col_class:'text-center pt-1 pl-1',col_cols:'1', w:'auto', h:'1.5cm' },
              { tag:'div', col_class:'text-center pt-0',col_cols:'12', class:'fs-17 ',  LABEL1: this.emp.nome , boldL1:'S' },
              { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-12 ',  LABEL1: this.dados_titulo1 , boldL1:'S' },
              { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-10 ',  LABEL1: this.dados_titulo2 , boldL1:'S' },
              { tag:'divider', col_cols:'12', class:'black my-1 mx-0'},

              { tag:'div', col_class:'mb-1 mr-n4',col_cols:'1', class:' fs-8',
                LABEL1: 'COD.⠀⠀⠀⠀⠀', boldL1:'S',
              },
              
              { tag:'div', col_class:'mb-1',col_cols:'1', class:' fs-8',
                LABEL2: 'CONTA⠀⠀⠀⠀', boldL2:'S', 
              },
              
              { tag:'div', col_class:'mb-1',col_cols:'1', class:' fs-8',
                LABEL3: 'VENCIMENTO⠀⠀⠀', boldL3:'S',
              },
              
              { tag:'div', col_class:'mb-1',col_cols:'1', class:' fs-8',
                LABEL4: 'EFETUADO⠀⠀⠀', boldL4:'S',
              },
              
              { tag:'div', col_class:'mb-1 ml-n1',col_cols:'1', class:' fs-8',
                LABEL5: 'TOTAL', boldL5:'S',
              },

              { tag:'div', col_class:'mb-1 ml-2 mr-n5',col_cols:'2', class:' fs-8',
                LABEL1: 'FORMA', boldL1:'S',
              },

              { tag:'div', col_class:'mb-1',col_cols:'3', class:' fs-8',
                LABEL1: 'REFERENTE⠀-⠀', boldL1:'S',
                LABEL2: 'ANOTAÇÃO', boldL2:'S', 
              },

            ])

            this.desenha('sub_tabela',
            [
                { tag:'div', col_class:'mr-n3',col_cols:'1', class:' fs-9', table: this.dados_financeiro,
                     F1: 'CODIGO', boldF1:'N', B1: ``,  
                },
                { tag:'div', col_class:'mr-n3',col_cols:'1', class:' fs-9', table: this.dados_financeiro,
                     F1: 'CONTA_REFERENTE', boldF1:'N', B1: ``,  
                },

                { tag:'div', col_class:'',col_cols:'1', class:' fs-9', table: this.dados_financeiro,
                     F3: 'x_DATA_VENCIMENTO',   boldF3:'N', B3: `${space}`,  
                },

                // AQUI EU FAÇO UM IF MOSTRANDO TEXTO BEFORE E AFTER
                { tag:'div', col_class:'mr-1',col_cols:'1', class:' fs-9', table: this.dados_financeiro,
                     F1: 'x_DATA_EFETUADO',     boldF1:'N', B1: `${space}`, 
                     vIFcampo : 'PAGO', vIFvalor : 'S' 
                },
                
                // AQUI EU FAÇO UM IF MOSTRANDO TEXTO BEFORE E AFTER
                { tag:'div', col_class:'mr-n13',col_cols:'2', class:' fs-8', table: this.dados_financeiro,
                     F1: 'x_double_TOTAL',     boldF1:'N', Bif1: `(-`, Aif1: `)`,
                     vIFcampo:'TIPO', vIFvalor: 'S',  
                },
                
                

                // margem negativa = mr-n12
                { tag:'div', col_class:'mr-n5', col_cols:'2', class:' fs-8', table: this.dados_financeiro,
                     F1: 'FORMA_PAGAMENTO',     boldF1:'N', B1: ``,  
                },
                
                
                { tag:'div', col_class:'',col_cols:'5', class:' fs-9', table: this.dados_financeiro,
                     F1: 'SERVICO_REFERENTE',   boldF1:'N', B1: ``,  
                     F2: 'OBSERVACAO',     boldF2:'N', B2: `⠀-⠀`,  
                },

                { tag:'divider', col_cols:'12', class:'black my-0 mx-0'},

            ])

            this.desenha('colunas',
            // this.desenha('footer',
            [
                { tag:'divider', col_cols:'12', class:'black my-0 mt-3 mx-10'},

                { tag:'div', col_class:'my-2 pa-2 elevation-2',col_cols:'3', class:'fs-11 ',  
                  LABEL1: this.dados_f_somas.c_entrada, boldL1:'N', B1: `Entradas:`, boldB1:'S',  
                  LABEL2: `R$ ` + this.getFormatCurrency(this.dados_f_somas.s_entrada,'N'), boldL2:'S', B2: ` - `, boldB2:'S',  
                },
                
                { tag:'div', col_class:'my-2 mx-4 pa-2 elevation-2',col_cols:'3', class:'fs-11 ',  
                  LABEL1: this.dados_f_somas.c_saida, boldL1:'N', B1: `Saidas:`, boldB1:'S',  
                  LABEL2: `R$ ` + this.getFormatCurrency(this.dados_f_somas.s_saida,'N'), boldL2:'S', B2: `-`, boldB2:'S',  
                },
                
                { tag:'div', col_class:'my-2 pa-2 elevation-2',col_cols:'5', class:'fs-11 ',  
                  LABEL1: this.dados_f_somas.c_total, boldL1:'N', B1: `Saldo:`, boldB1:'S',  
                  LABEL2: `R$ ` + this.getFormatCurrency(this.dados_f_somas.s_total,'N'), boldL2:'S', B2: `-`, boldB2:'S',  
                },

                { tag:'divider', col_cols:'12', class:'black my-0 mx-0'},
            ])
            
        }


        // FINAL
        // console.log(this.array_to_print);
    },




        // PASSO 1 - defino qual abrir
        async imprimir_modelo(){
  
          //pega o parametro verificador
          let qual = sessionStorage['recibo_tipo']
  
          this.modelo_recibo = qual

  
          this.mudaVias(this.nVias)

          this.monta_impressao(qual)
  
          // abre sql
          if (qual=='atendimento'){
              this.rel_atendimentos()
          }
  
        },
  
        mudaVias(vias){
              
            this.objVias = []//limpa
  
            let obj = {}
            for (let i = 1; i <=vias; i++){
                this.$set(obj, 'n',i)
                this.objVias.push(obj)
            }
        },
  
        carrega_dados(){
  
            // this.urlLogo =  sessionStorage.getItem('url')+'images/'+sessionStorage['urlLogo']
            this.urlLogo =  sessionStorage['emp_url_logo']
  
            this.$set(this.emp, 'nome',        sessionStorage.getItem('emp_nome'))
            this.$set(this.emp, 'razao',       sessionStorage.getItem('emp_razao'))
            this.$set(this.emp, 'fone1',       sessionStorage.getItem('emp_fone1'))
            this.$set(this.emp, 'fone2',       sessionStorage.getItem('emp_fone2'))
            this.$set(this.emp, 'celular',     sessionStorage.getItem('emp_celular'))
            this.$set(this.emp, 'cnpj',        sessionStorage.getItem('emp_cnpj'))
            this.$set(this.emp, 'cep',         sessionStorage.getItem('emp_cep'))
            this.$set(this.emp, 'endereco',    sessionStorage.getItem('emp_endereco'))
            this.$set(this.emp, 'cidade',      sessionStorage.getItem('emp_cidade'))
            this.$set(this.emp, 'uf',          sessionStorage.getItem('emp_uf'))
            this.$set(this.emp, 'email',       sessionStorage.getItem('emp_email'))
  

  
            // transformar em objeto novamente
            this.dados_parcela      =  (sessionStorage['recibo_dados_parcela'])    ? JSON.parse(sessionStorage['recibo_dados_parcela'])     : '';
            this.dados_cadastro     =  (sessionStorage['recibo_dados_cadastro'])   ? JSON.parse(sessionStorage['recibo_dados_cadastro'])    : '';
            this.dados_servico      =  (sessionStorage['recibo_dados_servico'])    ? JSON.parse(sessionStorage['recibo_dados_servico'])     : '';
            this.dados_financeiro   =  (sessionStorage['recibo_dados_financeiro']) ? JSON.parse(sessionStorage['recibo_dados_financeiro'])  : '';
            this.dados_f_somas      =  (sessionStorage['recibo_dados_financeiro_somas']) ? JSON.parse(sessionStorage['recibo_dados_financeiro_somas']) : '';

            this.dados_titulo1      = (sessionStorage['recibo_dados_titulo1']) ? sessionStorage['recibo_dados_titulo1'] : '';
            this.dados_titulo2      = (sessionStorage['recibo_dados_titulo2']) ? sessionStorage['recibo_dados_titulo2'] : '';

            this.define_data_hora()

            
            try { this.items_alunos_imprimir = JSON.parse(sessionStorage['items_alunos_imprimir']) }
            catch (error) { return false }
            
        },

  
  
          getSomatoria(array,filtrar_campo, filtrar_valor, campo_somar){
              // console.log(array);
              
                  
              if (filtrar_campo){
  
              
                 //filtro array pelo campo desejado
                  let h = array.filter( x => x[filtrar_campo] === filtrar_valor );
  
  
                  //faço a soma dos campos desejados
                  var sum = h.reduce(function(accumulator,object){ 
  
                  //converto formato float
                  let tofloat = object[campo_somar]
                  tofloat = tofloat.replaceAll('.','');
                  tofloat = tofloat.replaceAll(',','.');
  
                  return parseFloat(accumulator) + parseFloat(tofloat)
                  // return parseFloat(accumulator) + parseFloat(object[campo_somar])
                  },0);
  
                  return  sum
              }
              else{
  
                let A = array.map((x) => x); //clonando copiando array do jeito 
  
                  //faço a soma dos campos desejados
                var sumA = A.reduce(function(accumulator,object){ 
  
                //converto formato float
                let tofloat = object[campo_somar]
                tofloat = tofloat.replaceAll('.','');
                tofloat = tofloat.replaceAll(',','.');
  
                return parseFloat(accumulator) + parseFloat(tofloat)
                },0);
  
                return  sumA
  
              }
          },
  
          formatCurrency(vem){
              let r =  vem.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
              return r
          },
  
  
          display(item,index,array, A,B,C){
  
              let r = false
  
              if (C){
                  r = 
                      (index == 0) || 
                      (index>0 && array[index-1][A] != item[A]) || 
                      (index>0 && array[index-1][B] != item[B]) ||
                      (index>0 && array[index-1][C] != item[C]) 
                  
              }
              else if (B){
                  r = 
                  ((index == 0) || 
                  (index>0 && array[index-1][A] != item[A]) || 
                  (index>0 && array[index-1][B] != item[B]))
  
                  if (r == false){
                      // console.log('B false = '+ index);
                  }else{
                      // console.log('B true = '+ index);
                  }    
              }
              else if (A){
                  r =  (index == 0) || (index>0 && array[index-1][A] != item[A])
  
                  if (r == false){
                      // console.log('A false = '+ index);
                  }else{
                      // console.log('A true = '+ index);
                      // this.mais(index)
                      // return this.soma = index
                      
                  }    
              } 
  
              // count_qt
              return r
              
          },
  
          async rel_atendimentos(doc,conv,di,df){
  
              doc = sessionStorage.getItem('rel.doc')
              conv = sessionStorage.getItem('rel.conv')
              di = sessionStorage.getItem('rel.di')
              df = sessionStorage.getItem('rel.df')
  
  
              this.$set(this.recebe_obj, 'rel_doc', doc)
              this.$set(this.recebe_obj, 'rel_conv', conv)
              this.$set(this.recebe_obj, 'rel_di', this.getFormatDate(di))
              this.$set(this.recebe_obj, 'rel_df', this.getFormatDate(df))
  
              this.$set(this.recebe_obj, 'rel_prof', sessionStorage.getItem('rel.prof'))
  
              // console.log('doc:'+doc);
              // console.log('conv:'+conv);
              // console.log('di:'+di);
              // console.log('df:'+df);
  
  
              let sql_doc = ''
              if (doc>0){
              sql_doc = ` and (agenda.codigomedico = ${doc}) `
              }
  
              let sql_conv = ''
              if (conv>0){
              sql_conv = ` and (agenda.codigoconvenio = ${conv}) `
              }
  
  
              let sql = `
              SELECT
              AGENDA.DATA,
              AGENDA.HORA,
              AGENDA.NOME AS NOME_AGENDA,
              AGENDA.CONVENIO,
              AGENDA.PROCEDIMENTO,
              AGENDA.VALOR,
              '1' AS CONTAR
              FROM
              AGENDA
              WHERE
              (AGENDA.DATA BETWEEN '${di}' AND '${df}') AND
              (AGENDA.ATENDIDO IN ('G','A','T'))
              `
              +sql_doc + sql_conv+ 
              `
              ORDER BY
              AGENDA.CONVENIO,
              AGENDA.PROCEDIMENTO,
              AGENDA.DATA,
              AGENDA.HORA
              `
  
              // console.log(sql);
              await this.crud_abrir_tabela(sql, 'items_retorno' )
              // console.log(this.items_retorno);
  
  
          },
  
          imprimir(item){       
              window.print()
          },
  
          close(){
              // this.$router.push('/agenda')//redireciono para a pagina do router
              window.close()
          },
  
          getFormatDate(data){

            if (data){
                let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
                return dia 
            }
            else{
                return '' 
            }

        },
  
  
          async crud_abrir_tabela(sql, items_name){
             await DSisql.crud_abrir_tabela(this, sql, items_name)
          // console.log(this.items_mensagens);
          }, 
  
  
          define_data_hora(){
              DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
          },

          getFormatCurrency(v,string){
             return DSibasico.getFormatCurrency(v, string)
          },
  
      }
  }
  
  </script>
  
  <style scoped >
/* //    <style lang="scss"> */
  

  @page {
    size: auto !important;
    margin: 1cm !important;
  }
  
  @page:first {
     /* margin-top: 0 !important;            */
  }  

    .container{
      max-width: 100%;
      color: red;
    }
  
  
    .inline{
    display: inline  !important;
  }

  .colunas_bottom{
    /* border-left: 1px  solid black; */
    border-right: 1px  solid black;
    border-bottom: 1px  solid black;
    border-top: 1px  solid black;
  }
  
  .cap{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  
  .none{
    font-weight: normal;
    /* font-weight (bold, bolder, lighter, normal e 100-900 */
  }
  
  .h7{
    font-size: 9px !important;
    /* color: red !important; */
  }
  .cap_soma{
    font-size: 12px !important;
    /* color: red !important; */
    display: inline;
    font-weight: bold;
  }
  
  .header{
      background-color: #E0E0E0;
      -webkit-print-color-adjust: exact; /* força imprimir com a cor defina aqui */
  }
  
  .sem_borda{
       /* sem borda */
      border: none !important;
      box-shadow: none !important;
  }
  
  @media print {

    .pagebreak {
        clear: both !important;
        page-break-after: always !important;
    }

    .no-print {
      display: none !important;
    }
  
    .full{
      width: 95%; /* preenche toda a tela */
    }
  }
  

  .footer_class{
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
  }
  

  .fs-7{
    font-size: 7px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-9{
    font-size: 9px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-17{
    font-size: 17px !important;
  }
  .bold{
    font-weight: bold !important;
  }
  
  /* lang="scss" */
  </style>